const german = {
  "add_doctor_dialog.confirmation_text": "Hiermit bestätige ich, dass mein Arzt mit der Kontaktaufnahme durch PharmGenetix einverstanden ist.",
  "add_doctor_dialog.description": "BITTE BEACHTEN: Da es sich bei der Software PGx-Optimizer®, um ein Medizinprodukt handelt, ist eine Schulung des Arztes zur richtigen Handhabung verpflichtend. Die Schulung wird ca. 30 Minuten in Anspruch nehmen. Besprechen Sie bitte vorab mit Ihrem Arzt, ob er sich schulen lassen möchte. Sie können den ergänzten Arzt jederzeit wieder entfernen.",
  "add_doctor_dialog.download_informations_pdf": "Arzt noch nicht informiert",
  "add_doctor_dialog.form.city_error": "Bitte geben Sie einen Ort ein",
  "add_doctor_dialog.form.firstname_error": "Bitte geben Sie einen Vornamen ein",
  "add_doctor_dialog.form.lastname_error": "Bitte geben Sie einen Nachnamen ein",
  "add_doctor_dialog.form.mail_error": "Bitte geben Sie eine gültige E-Mail Adresse ein",
  "add_doctor_dialog.form.next.text": "Arzt ist an PGx-Schulung interessiert",
  "add_doctor_dialog.form.phone_error": "Bitte geben Sie eine gültige Telefonnummer ein",
  "add_doctor_dialog.form.submit.text": "Anfrage absenden",
  "add_doctor_dialog.form_group.contact_data.text": "Kontaktdaten des Arztes",
  "add_doctor_dialog.form_group.data.text": "Daten des Arztes",
  "add_doctor_dialog.headline": "Arzt hinzufügen",
  "add_doctor_dialog.label.city": "Ort",
  "add_doctor_dialog.label.contact_type": "Kontakt Art",
  "add_doctor_dialog.label.email": "E-Mail",
  "add_doctor_dialog.label.firstname": "Vorname",
  "add_doctor_dialog.label.lastname": "Nachname",
  "add_doctor_dialog.label.phone": "Telefon",
  "add_doctor_dialog.label.zip": "PLZ",
  "summary_dialog.description": "Es wird eine druckbare Zusammenfassung der Patientendaten erstellt. Geben Sie den Adressaten (behandelnden Arzt) und ggf. dessen medizinische Fachrichtung an.",
  "summary_dialog.form.firstname_error": "Bitte geben Sie einen Vornamen ein",
  "summary_dialog.form.lastname_error": "Bitte geben Sie einen Nachnamen ein",
  "summary_dialog.form.submit.text": "Zusammenfassung erstellen",
  "summary_dialog.headline": "Zusammenfassung erstellen",
  "summary_dialog.label.field": "Fachrichtung",
  "summary_dialog.label.firstname": "Vorname",
  "summary_dialog.label.lastname": "Nachname",
  "summary_dialog.generic_error": "Fehler beim Erstellen des Summary Reports.",
  "summary_dialog.medication_mismatch_error": "Die angezeigten Daten sind veraltet. Bitte aktualisieren Sie die Seite, um die neuesten Informationen zu erhalten.",
  "common.enzyme.interaction_with": "Interaktion mit",
  "common.enzyme.weight": "Einfluss auf das Gesamtergebnis",
  "common.enzyme.weight.high": "hoch",
  "common.enzyme.weight.low": "gering",
  "common.enzyme.weight.medium": "durchschnittlich",
  "common.error": "Error",
  "common.here": "hier",
  "common.plan.basic": "Basic",
  "common.plan.totalCare": "Total Care",
  "common.print": "Drucken",
  "common.print.close": "Druckansicht schließen",
  "common.yes": "Ja",
  "common.no": "Nein",
  "common.unexpectedError":"Ups! Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut.",
  "confirm_delete_dialog.delete": "Löschen",
  "confirm_delete_dialog.headline.text": "Wollen Sie diese News wirklich löschen?",
  "confirm_resend_dialog.cancel.label": "Abbrechen",
  "confirm_resend_dialog.headline.text": "Wollen Sie die Einladung wirklich versenden?",
  "confirm_resend_dialog.resend_password.label": "Einladung versenden",
  "confirm_reset_dialog.headline.text": "Wollen Sie das Passwort wirklich zurücksetzen?",
  "confirm_reset_dialog.label": "Passwort zurücksetzen",
  "date.cancelLabel": "Abbrechen",
  "date.clearLabel": "Löschen",
  "date.validation_error": "Bitte geben Sie ein gültiges Datum ein",
  "dialog.button_cancel": "Abbrechen",
  "dialog.button_confirm": "Bestätigen",
  "dialog.button_lock": "Sperren",
  "dialog.button_next": "Weiter",
  "dialog.button_release": "Freigeben",
  "dialog.button_save": "Speichern",
  "doctor.appUsageWarningDialog.button.text": "Gelesen und verstanden",
  "doctor.appUsageWarningDialog.text": `
      Bitte beachten Sie, dass das pharmakogenetische Ergebnis, die Interaktionsanalyse und die Dosisempfehlung nur als Information für GesundheitsexpertInnen angesehen werden können.<br><br>
      Sie können die Erfahrung der behandelnden Ärztin oder des behandelnden Arztes und deren/dessen Kenntnis bei der Erstellung der Diagnose oder des Behandlungsplans nicht ersetzen und entheben sie/ihn nicht von deren Sorgfaltspflicht sowie Verschreibungsverantwortung.<br><br>
      Jede medikamentöse Verschreibung bedarf einer gründlichen und gewissenhaften Wahl basierend auf der Anamnese und dem klinischen Bild sowie einer entsprechenden Beobachtung des Patienten nach Verabreichung des Medikaments.<br><br>
      Die hier berechnete Medikamenten-Interaktion bezieht sich nur auf die Genetik des Medikamenten-Stoffwechsels. Es ist also zu beachten, dass zusätzlich andere Interaktionen auftreten können, welche hier nicht berücksichtigt werden.<br><br>
      Außerdem ist zu beachten, dass der Phänotyp durch seltene DNA-Varianten die unbekannt sind oder funktionell nicht getestet wurden, verändert sein kann.`,
  "doctor.appUsageWarningDialog.title": "Anwendungshinweis",
  "doctor.notReleasedReportInfo": "Es ist noch eine Befundbesprechung offen.",
  "doctor.notReleasedReportsInfo": "Es sind noch {patientsWithoutReleasedReport} Befundbesprechungen offen.",
  "patient.replaceWarningDialog.approval.text": "Gelesen und verstanden",
  "patient.replaceWarningDialog.text": "Bitte beachten Sie, dass eine endgültige Therapieentscheidung nur von einem Arzt getroffen werden kann. Die Ergebnisse einer pharmakogenetischen Analyse beschreiben nicht das Gesamtbild des Patienten und sind nur unter der Betrachtung des kompletten Krankheitsbildes (zB Nierenfunktion, Alter, Gewicht, Allergien, etc) anwendbar. Nehmen Sie Abstand davon, Ihre Medikationen eigenmächtig und unkontrolliert in der Dosis zu verändern oder sie abzusetzen. Es ist vor jeglicher Änderung ein Arzt zu konsultieren.",
  "patient.replaceWarningDialog.title": "Hinweis",
  "dropdown.label.atcGroups": "ATC Gruppen",
  "dropzone.button.label_empty": "DATEIEN DURCHSUCHEN",
  "dropzone.button.label_selected": "Datei ersetzen",
  "error.addDoctor": "Arzt/Ärztin konnte nicht angelegt werden",
  "error.addDoctor.emailAlreadyInUse": "Arzt ID existiert bereits",
  "error.addPatient": "Patient konnte nicht angelegt werden",
  "error.calculate": "Interaktionen konnten nicht geladen werden",
  "error.copy": "Fehler beim Kopieren in die Zwischenablage",
  "error.csv_export": "Fehler beim Erstellen der CSV Datei",
  "error.downloadReport": "Fehler beim Herunterladen des Reports",
  "error.editDoctor": "Arzt konnte nicht  bearbeitet werden",
  "error.editPatient": "Patient konnte nicht bearbeitet werden",
  "error.emailAlreadyInUse": "Arzt ID existiert bereits",
  "error.errorScreenMessage": "Versuchen Sie es in Kürze erneut. Falls der Fehler weiterhin besteht, klicken Sie {errorLink} um uns zu kontaktieren.",
  "error.getStatus": "Fehler beim Laden des Status",
  "error.loadDoctorsList": "Fehler beim Laden der Ärzteliste",
  "error.loadMedication": "Fehler beim Laden der Medikation",
  "error.loadPatient": "Fehler beim Laden der Patientendaten",
  "error.loadPatientsList": "Fehler beim Laden der Patientenliste",
  "error.loadSingleMedGroups": "Fehler beim Laden der Medikamentengruppen",
  "error.loadSubstanceData": "Fehler beim Laden der notwendigen Substanz-Daten",
  "error.loadSubstances": "Fehler beim Laden der Wirkstoffliste",
  "error.optimize": "Fehler beim Optimieren der Medikation",
  "error.optimizer_timeout": "Die Berechnung war zu komplex und wurde daher abgebrochen. Bitte ziehen Sie in Betracht, die Anzahl der gewählten Substanzen zu verringern oder einige davon zu fixieren.",
  "error.releaseReport": "Fehler bei der Freigabe",
  "error.resendEmail": "E-Mail konnte nicht versendet werden",
  "error.resetPassword": "Passwort konnte nicht zurückgesetzt werden",
  "error.saveMedication": "Fehler beim Speichern der Medikation",
  "error.serviceUnavailable": "Der Service ist derzeit leider nicht verfügbar. Bitte versuchen Sie es später erneut.",
  "gender_disclaimer": "Ausschließlich zum Zweck der besseren Lesbarkeit wird auf die geschlechtsspezifische Schreibweise verzichtet. Alle personenbezogenen Bezeichnungen sind somit geschlechtsneutral zu verstehen.",
  "hisoty.open_history_button.text": "Version öffnen",
  "history.description": "Alle Versionen der Medikamentenanalyse werden hier datiert gespeichert. Wählen Sie eine frühere Version aus, um sich diese im Detail anzusehen oder um diese weiterzubearbeiten.",
  "history.info.headline": "Aktualität der Medikamentenanalyse",
  "history.info.text": "Die Datenbank wird stets entsprechend neuer wissenschaftlicher Erkenntnisse aktualisiert. Ältere Analysen können daher nach einem automatischen Abgleich mit der aktuellen Datenbank andere Schlussfolgerungen ergeben.",
  "history.open_history_button.text": "Version öffnen",
  "history.title": "Analysehistorie",
  "language.de": "Deutsch",
  "language.en": "Englisch",
  "language.english": "Englisch",
  "language.german": "Deutsch",
  "language.it": "Italienisch",
  "language.italian": "Italienisch",
  "language.language": "Sprache",
  "loadingscreen.heading": "Berechnung läuft ...",
  "loadingscreen.info_text": "Überprüfe mögliche Kombinationen",
  "navigation.help.contact": "Kontakt",
  "navigation.help.label": "HILFE",
  "navigation.help.manual": "Anleitung",
  "navigation.help.privacy_policy": "Datenschutzerklärung",
  "navigation.help.site_notice": "Impressum",
  "navigation.logout.label": "Abmelden",
  "news.content": "Inhalt",
  "news.date": "Datum",
  "news.title": "Titel",
  "news.patientEnabled": "Für Patienten",
  "news.doctorEnabled": "Für Ärzte",
  "news.published": "Veröffentlicht",
  "news.unpublished": "Nicht veröffentlicht",
  "news.unreadNewsDialog.button.text": "Gelesen",
  "news.unreadNewsDialog.title": "News",
  "news_modal.create_news": "Neuen Beitrag erstellen",
  "news_modal.edit_news": "Beitrag bearbeiten",
  "news_modal.form.error.content": "Bitte geben Sie einen Inhalt ein",
  "news_modal.form.error.title": "Bitte geben Sie einen Titel ein",
  "news_modal.form.label.content": "Inhalt",
  "news_modal.form.label.title": "Titel",
  "news_modal.form.label.patientEnabled": "Für Patienten veröffentlichen",
  "news_modal.form.label.doctorEnabled": "Für Ärzte veröffentlichen",
  "news_modal.publish": "Veröffentlichen",
  "optimizer.legend.danger.text": "Alternative erwägen",
  "optimizer.legend.decrease.high.text": "Stark",
  "optimizer.legend.decrease.low.text": "Leicht",
  "optimizer.legend.decrease.medium.text": "Mittel",
  "optimizer.legend.enzymeActivity.danger.text": "Kritische Variante oder kritische Interaktion",
  "optimizer.legend.enzymeActivity.decrease.high.text": "Stark",
  "optimizer.legend.enzymeActivity.decrease.low.text": "Leicht",
  "optimizer.legend.enzymeActivity.decrease.medium.text": "Mittel",
  "optimizer.legend.enzymeActivity.heading": "Enzymaktivität",
  "optimizer.legend.enzymeActivity.increase.high.text": "Stark",
  "optimizer.legend.enzymeActivity.increase.low.text": "Leicht",
  "optimizer.legend.enzymeActivity.increase.medium.text": "Mittel",
  "optimizer.legend.enzymeActivity.okay.text": "Normale Enzymfunktion",
  "optimizer.legend.enzymeActivity.poorMetabolizer.text": "Poor Metabolizer",
  "optimizer.legend.enzymeActivity.section.decrease.text": "Enzymaktivität reduziert:",
  "optimizer.legend.enzymeActivity.section.increase.text": "Enzymaktivität erhöht:",
  "optimizer.legend.enzymeActivity.section.okay.text": "In Ordnung:",
  "optimizer.legend.enzymeActivity.section.other.text": "Sonstige:",
  "optimizer.legend.enzymeActivity.section.warnings.text": "Warnungen:",
  "optimizer.legend.enzymeActivity.ultraRapidMetabolizer.text": "Ultra Rapid Metabolizer",
  "optimizer.legend.enzymeActivity.unknown.text": "Nicht getestet",
  "optimizer.legend.heading": "Handlungsempfehlungen",
  "optimizer.legend.heading.info": "Handlungsempfehlungen laut dem aktuellen Wissensstand und der erhaltenen Ergebnisse der PGx-Analyse.",
  "optimizer.legend.increase.high.text": "Stark",
  "optimizer.legend.increase.low.text": "Leicht",
  "optimizer.legend.increase.medium.text": "Mittel",
  "optimizer.legend.info": "Bei Wirkstoffen die nicht in der Datenbank aufscheinen ist die pharmakogenetische Relevanz noch unbekannt, nicht ausreichend überprüft oder nicht gegeben. Die Wirkstoff-Datenbank wird regelmäßig auf Basis von Empfehlungen der Zulassungsbehörden, der internationalen wissenschaftlichen PGx-Konsortien sowie auf Basis von medizinischer Fachliteratur (peer-reviewed) aktualisiert.",
  "optimizer.legend.information.cpic": "CPIC Guidelines",
  "optimizer.legend.information.text": "Mehr Informationen:",
  "optimizer.legend.notTested.badge.text": "Nicht CYP metabolisiert",
  "optimizer.legend.okay.text": "Dosierung lt. Fachinformation",
  "optimizer.legend.section.decrease.text": "Reduktion der Dosierung erwägen:",
  "optimizer.legend.section.increase.text": "Erhöhung der Dosierung erwägen:",
  "optimizer.legend.section.not_recommended.badge.text": "Wirkstoff nicht empfohlen",
  "optimizer.legend.section.okay.text": "In Ordnung:",
  "optimizer.legend.section.other.text": "Sonstige:",
  "optimizer.legend.section.warning.badge.text": "Erhöhtes Risiko für Nebenwirkungen aufgrund von Mutationen \neines Transporters oder gelinkten Proteins",
  "optimizer.legend.section.warnings.text": "Warnungen:",
  "optimizer.legend.unknown.badge.text": "Nicht getestet",
  "password_strength_checker.info_text_begin": "Fügen Sie",
  "password_strength_checker.info_text_end": "hinzu, um Ihr Passwort noch mehr zu schützen",
  "password_strength_checker.lower_case_letter": "Kleinbuchstaben",
  "password_strength_checker.medium_password": "Mittleres Passwort",
  "password_strength_checker.password_too_short": "Das Passwort sollte mindestens 8 Zeichen lang sein.",
  "password_strength_checker.special_character": "Sonderzeichen",
  "password_strength_checker.strong_password": "Starkes Password",
  "password_strength_checker.upper_case_letter": "Großbuchstaben",
  "password_strength_checker.weak_password": "Schwaches Passwort",
  "screen.doctor.auto_optimize.heading": "Automatisch berechnete Ersatzkombinationen",
  "screen.doctor.auto_optimize.navigation.replace_automatically": "Automatisch ersetzen",
  "screen.doctor.auto_optimize.no_enzymes_available": "Keine Enzyme verfügbar",
  "screen.doctor.auto_optimize.section.combination": "Kombination",
  "screen.doctor.auto_optimize.section.combination.current": "DERZEIT",
  "screen.doctor.auto_optimize.section.combination.replacement": "ERSATZKOMBI",
  "screen.doctor.auto_optimize.section.combination.select": "WÄHLEN",
  "screen.doctor.auto_optimize.section.combinations": "Kombinationen",
  "screen.doctor.auto_optimize.section.replacement_in_different_level": "Mögliche Ersatzkombinationen",
  "screen.doctor.auto_optimize.section.replacement_in_different_level.description": "ATC Austausch möglich",
  "screen.doctor.auto_optimize.section.replacement_in_same_level": "Ersatzkombination im selben ATC Level",
  "screen.doctor.auto_optimize.section.replacement_in_same_level.description": "im selben ATC Level",
  "screen.doctor.auto_optimize.show_less_information": "ENZYME VERBERGEN",
  "screen.doctor.auto_optimize.show_more_information": "ENZYME ANZEIGEN",
  "screen.doctor.auto_optimize.text": "Wählen Sie eine Kombination aus den Optimierungsvorschlägen.",
  "screen.doctor.manual_optimize.enzyme.cyp3a5.info": "Weltweit ist die *3 Mutation dominant mit 88,7% und damit stellt die stark reduzierte Enzymaktivität von CYP3A5 die ‘normale’ Enzymaktivität dar.",
  "screen.doctor.manual_optimize.interaction_preview.explanation": "Voransicht des veränderten Zusammenspiel aller Wirkstoffe mit dem Ersatzmedikament für {substance}.",
  "screen.doctor.manual_optimize.interaction_preview.heading": "Voransicht der Interaktion",
  "screen.doctor.manual_optimize.interaction_preview.hide_legend": "LEGENDE VERBERGEN",
  "screen.doctor.manual_optimize.interaction_preview.show_legend": "LEGENDE ANZEIGEN",
  "screen.doctor.manual_optimize.interaction_preview.show_less_information": "ENZYME VERBERGEN",
  "screen.doctor.manual_optimize.interaction_preview.show_more_information": "ENZYME ANZEIGEN",
  "screen.doctor.manual_optimize.navigation.replace_manually": "Wirkstoff manuell ersetzen",
  "screen.doctor.manual_optimize.prodrug.title": "Prodrug",
  "screen.doctor.manual_optimize.prodrug.text": "Ist bereits in der Kalkulation miteinberechnet.",
  "screen.doctor.manual_optimize.substance_picker.dropdown.option.substance_count.text": "Wirkstoffe",
  "screen.doctor.manual_optimize.substance_picker.heading": "Ersatzwirkstoffe",
  "screen.doctor.manual_optimize.substance_picker.keep_substance.button.text": "Wirkstoff beibehalten",
  "screen.doctor.manual_optimize.substance_picker.save_substance.button.text": "Ersatzwirkstoff übernehmen",
  "screen.doctor.manual_optimize.substance_picker.select_substance.text": "Wählen Sie einen Wirkstoff:",
  "screen.doctor.navigation.news": "News",
  "screen.doctor.navigation.patient_overview": "Patientenübersicht",
  "screen.doctor.news.description": "Hier finden Sie alle aktuellen Informationen zum PGx-Optimizer.",
  "screen.doctor.optimize.actions.analysisHistory": "Analysehistorie",
  "screen.doctor.optimize.actions.reset": "WIEDERHERSTELLEN",
  "screen.doctor.optimize.actions.searchbar.placeholder": "Wirkstoff oder Handelsname suchen und hinzufügen",
  "screen.doctor.optimize.heading": "Medikamentencheck",
  "screen.doctor.optimize.history_information.headline": "Version vom {date}, erstellt  durch {doctorName}",
  "screen.doctor.optimize.no_medication.heading": "Informieren Sie den Patienten über die Verträglichkeit ausgewählter Medikamente.",
  "screen.doctor.optimize.no_medication.to_medication_groups": "Zu Medikamentengruppen",
  "screen.doctor.optimize.optimize_medicaction": "Medikamente optimieren",
  "screen.doctor.optimize.replace_automatically.replacement.heading": "Zu verabreichende Wirkstoffe",
  "screen.doctor.optimize.replace_automatically.replacement.save": "Speichern",
  "screen.doctor.optimize.replace_automatically.replacement.sub_heading": "Hier sehen Sie das Ergebnis der neuen Berechnung. Sie können dieses abspeichern und zu einem späteren Zeitpunkt unter  \"Analysehistorie\" erneut aufrufen. ",
  "screen.doctor.optimize.sub_text": "Geben Sie hier die Wirkstoffe oder Arzneimittel ein, die Sie analysieren möchten.",
  "screen.doctor.optimize.getSummary": "Summary Report erstellen",
  "screen.doctor.optimize.timeout.warning": "Achtung: Diese Berechnung dauert möglicherweise zu lange. Bitte reduzieren Sie die Anzahl der gewählten Wirkstoffe.",
  "screen.doctor.patients.empty_list.heading": "Es stehen noch keine Patientendaten zur Verfügung.",
  "screen.doctor.patients.empty_list.pharmgenetix_info": "Über PharmGenetix",
  "screen.doctor.patients.empty_list.text": "Sobald die erste Blutprobe eingesandt wurde, finden Sie hier das Ergebnis der Analyse und eine Übersicht aller Proben. ",
  "screen.doctor.patients.searchbar.placeholder": "Patient suchen",
  "screen.doctor.replacement_notice.dialog.placeholder": "Begründung für die Wahl der Ersatzkombination",
  "screen.doctor.replacement_notice.dialog.submit_with_notice": "Notiz anhängen",
  "screen.doctor.replacement_notice.dialog.submit_without_notice": "Weiter ohne Notiz",
  "screen.doctor.replacement_notice.dialog.text": "Es wäre eine optimalere Ersatzkombination verfügbar. Möchten Sie die Kombination trotzdem wählen und eine Begründung hinzufügen?",
  "screen.doctor.replacement_notice.dialog.title": "Notiz hinzufügen",
  "screen.doctor.select_group.dialog.dialog.form.multiple.group.validation_error": "Bitte wählen Sie mindestens eine Wirkstoffgruppe",
  "screen.doctor.select_group.dialog.dialog.form.single.group.validation_error": "Bitte wählen Sie eine Wirkstoffgruppe",
  "screen.doctor.select_group.dialog.multiple_groups.text": "Bitte wählen Sie eine oder mehrere Gruppen, in welcher der Wirkstoff ersetzt werden soll.",
  "screen.doctor.select_group.dialog.single_group.text": "Bitte wählen Sie die Gruppe, in welcher der Wirkstoff ersetzt werden soll.",
  "screen.forgot_password.description": "Sie erhalten einen Link per E-Mail, mit dem Sie Ihr Passwort zurücksetzen können.",
  "screen.forgot_password.form.email.label": "E-Mail",
  "screen.forgot_password.form.email.validation_error": "Bitte geben Sie eine gültige E-Mail Adresse ein",
  "screen.forgot_password.form.error": "E-Mail konnte nicht versendet werden",
  "screen.forgot_password.form.submit": "Link senden",
  "screen.forgot_password.form.success": "E-Mail wurde erfolgreich versendet",
  "screen.forgot_password.title": "Passwort vergessen",
  "screen.login.error_during_login": "Bei der Anmeldung ist ein Fehler aufgetreten.",
  "screen.login.form.email.label": "E-Mail",
  "screen.login.form.email.validation_error": "Bitte geben Sie eine gültige E-Mail Adresse ein",
  "screen.login.form.forgot_password": "PASSWORT VERGESSEN?",
  "screen.login.form.password.label": "Passwort",
  "screen.login.form.password.validation_error": "Bitte geben Sie ein Passwort ein",
  "screen.login.form.submit": "Anmelden",
  "screen.login.form.validation_error_email_and_password": "Bitte geben Sie eine gültige E-Mail Adresse und Ihr Passwort ein",
  "screen.login.invalid_password_or_email": "Login fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mail Adresse und das Passwort.",
  "screen.not_found.title": "Die Seite konnte leider nicht gefunden werden.",
  "screen.patient.medication_check.heading": "Medikamentencheck",
  "screen.patient.medication_check.text": "Übersicht getesteter Wirkstoffe als Einzelmedikation.",
  "screen.patient.medication_groups.heading": "Medikamente und Wirkstoffgruppen",
  "screen.patient.medication_groups.print.name.label": "PATIENTENNAME",
  "screen.patient.medication_groups.text": "Wirkstoffe gelistet als Einzelmedikation, ohne Interaktionsanalyse.",
  "screen.pgx_admin.add_edit_doctor.dialog.contact_details.text": "Kontaktdaten",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.helper_text": "Mit dieser E-Mail Adresse erhält der Benutzer automatisch Zugang zu pharmgenetix.com.",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.label": "E-Mail",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.validation_error": "Bitte geben Sie eine gültige E-Mail Adresse ein",
  "screen.pgx_admin.add_edit_doctor.dialog.form.firstname.label": "Vorname",
  "screen.pgx_admin.add_edit_doctor.dialog.form.firstname.validation_error": "Bitte geben Sie einen Vornamen ein",
  "screen.pgx_admin.add_edit_doctor.dialog.form.language": "Standardsprache",
  "screen.pgx_admin.add_edit_doctor.dialog.form.lastname.label": "Nachname",
  "screen.pgx_admin.add_edit_doctor.dialog.form.lastname.validation_error": "Bitte geben Sie einen Nachnamen ein",
  "screen.pgx_admin.add_edit_doctor.dialog.form.pharmgenetix_id.label": "PharmGenetix Arzt-ID (optional)",
  "screen.pgx_admin.add_edit_doctor.dialog.personal_details.text": "Persönliche Daten",
  "screen.pgx_admin.add_edit_doctor.dialog.title": "Neuer Arzt",
  "screen.pgx_admin.add_edit_patient.dialog.contact_details.text": "Kontaktdaten",
  "screen.pgx_admin.add_edit_patient.dialog.form.birthdate.label": "Geburtsdatum (DD.MM.JJJJ)",
  "screen.pgx_admin.add_edit_patient.dialog.form.birthdate.validation_empty_error": "Bitte geben Sie ein Geburtsdatum ein",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.helper_text": "Falls der Name des Arztes nicht gelistet ist, legen Sie diesen zuerst im Bereich „Ärzteverwaltung“ an.",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.label": "Name des Arztes / der Ärztin",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.validation_error": "Bitte wählen Sie einen Arzt / eine Ärztin",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.helper_text": "Sobald der Befund verfügbar ist, erhält der Patient auf diese Adresse den Zugang zu pharmgenetix.com.",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.label": "E-Mail",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.validation_error": "Bitte geben Sie eine gültige E-Mail Adresse ein",
  "screen.pgx_admin.add_edit_patient.dialog.form.firstname.label": "Vorname",
  "screen.pgx_admin.add_edit_patient.dialog.form.firstname.validation_error": "Bitte geben Sie einen Vornamen ein",
  "screen.pgx_admin.add_edit_patient.dialog.form.language": "Standardsprache",
  "screen.pgx_admin.add_edit_patient.dialog.form.lastname.label": "Nachname",
  "screen.pgx_admin.add_edit_patient.dialog.form.lastname.validation_error": "Bitte geben Sie einen Nachnamen ein",
  "screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.label": "PGx Datei",
  "screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.validation_empty_error": "Bitte wählen Sie eine PGx Datei (.pgx-report)",
  "screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.label": "PharmGenetix Patienten-ID",
  "screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.validation_error": "Bitte geben Sie die Patienten-ID ein",
  "screen.pgx_admin.add_edit_patient.dialog.form.report_file.label": "Befund als PDF",
  "screen.pgx_admin.add_edit_patient.dialog.form.report_file.validation_empty_error": "Bitte wählen Sie eine PDF Datei aus",
  "screen.pgx_admin.add_edit_patient.dialog.personal_details.text": "Persönliche Daten",
  "screen.pgx_admin.add_edit_patient.dialog.responsible_doctor.text": "Zuständiger Arzt / Zuständige Ärztin",
  "screen.pgx_admin.add_edit_patient.dialog.title": "Neuer Patient",
  "screen.pgx_admin.confirm_dialog.description.doctor": "Sind Sie sicher, dass Sie diesen Arzt löschen wollen?",
  "screen.pgx_admin.confirm_dialog.description.link_to_patient_text": "zum Patienten",
  "screen.pgx_admin.confirm_dialog.description.patient": "Sind Sie sicher, dass Sie diesen Patienten löschen wollen?",
  "screen.pgx_admin.confirm_dialog.description.reassign_patients": "Um diesen Arzt zu löschen, müssen Sie dem Patienten zuerst einen neuen Arzt zuweisen.",
  "screen.pgx_admin.confirm_dialog.headline.doctor": "Arzt löschen",
  "screen.pgx_admin.confirm_dialog.headline.patient": "Patient löschen",
  "screen.pgx_admin.csv_export.button.label": "CSV EXPORT",
  "screen.pgx_admin.doctors.actions.add.button.label": "Neuer Arzt",
  "screen.pgx_admin.doctors.actions.searchbar.placeholder": "Arzt suchen",
  "screen.pgx_admin.doctors.empty_list.add.button.label": "Ersten Arzt hinzufügen",
  "screen.pgx_admin.doctors.empty_list.text": "Sie haben noch keinen Arzt hinzugefügt.",
  "screen.pgx_admin.navigation.tab.doctor_management": "Ärzteverwaltung",
  "screen.pgx_admin.navigation.tab.news": "News",
  "screen.pgx_admin.navigation.tab.patient_management": "Patientenverwaltung",
  "screen.pgx_admin.news.add_news": "Neuen Beitrag erstellen",
  "screen.pgx_admin.patients.actions.add.button.label": "Neuer Patient",
  "screen.pgx_admin.patients.actions.searchbar.placeholder": "Patient suchen",
  "screen.pgx_admin.patients.empty_list.add.button.label": "Ersten Patienten hinzufügen",
  "screen.pgx_admin.patients.empty_list.text": "Sie haben noch keinen Patienten hinzugefügt.",
  "screen.set_password.description": "Use special characters, upper and lower case letters and numbers. Your password must contain min. 8 characters.",
  "screen.set_password.form.error_expired_token": "The provided link has expired. Please request a new one.",
  "screen.set_password.form.error_invalid_token": "The provided link is invalid. Please request a new one.",
  "screen.set_password.form.error_weak_password": "The password you provided doesn't meet the requirements to be secure. Please choose another password.",
  "screen.set_password.form.password.confirm.label": "Confirm new password",
  "screen.set_password.form.password.confirm.required.validation_error": "Please confirm your password",
  "screen.set_password.form.password.confirm.validation_error": "Passwords do not match",
  "screen.set_password.form.password.label": "New password",
  "screen.set_password.form.password.validation_error": "Please enter a valid password",
  "screen.set_password.form.submit": "Set new password",
  "screen.set_password.form.success": "Your new password has been set successfully. You will be redirected to the login page automatically.",
  "screen.set_password.invalid_token.description": "The link provided is no longer valid. If you have already assigned the password, you can log in directly.",
  "screen.set_password.invalid_token.title": "Invalid Link",
  "screen.set_password.title": "Set password",
  "select.no_options": "Keine Optionen",
  "success.copy": "in Zwischenablage kopiert",
  "success.resendEmail": "E-Mail wurde erfolgreich versendet",
  "table.action.analyse": "ANALYSE",
  "table.action.copy.passwordResetLink": "Passwort Reset Link kopieren",
  "table.action.copy.patient": "Patientenlink kopieren",
  "table.action.copy.title": "Link kopieren",
  "table.action.delete.doctor": "Arzt löschen",
  "table.action.delete.patient": "Patient löschen",
  "table.action.delete.title": "Aus Liste entfernen",
  "table.action.delete_doctor.text": "Arzt löschen",
  "table.action.delete_patient.description.text": "Wollen Sie den Patienten wirklich löschen?",
  "table.action.delete_patient.headline.text": "Patient löschen?",
  "table.action.delete_patient.text": "Patient löschen",
  "table.action.download_report.title": "Befund downloaden",
  "table.action.dropdown.download_report": "Befund downloaden",
  "table.action.dropdown.medication_check": "Medikamentencheck",
  "table.action.dropdown.medication_group": "Medikamentengruppe",
  "table.action.edit.title": "Bearbeiten",
  "table.action.fix": "FIXIEREN",
  "table.action.fixed": "FIXIERT",
  "table.action.locked_report.title": "Befund noch nicht freigegeben",
  "table.action.medication_check.title": "Medikamentencheck",
  "table.action.medication_groups.title": "Medikamentengruppen",
  "table.action.optimize": "OPTIMIEREN",
  "table.action.unreleasedReport": "Befundbesprechung ausstehend",
  "table.action.releasedReport": "Befundbesprechung hat stattgefunden",
  "table.action.reset_password.title": "Passwort zurücksetzen",
  "table.action.send_invitation.title": "Einladung versenden",
  "table.heading.active_agent": "WIRKSTOFF",
  "table.heading.birthdate": "GEBURTSD.",
  "table.heading.checksum": "Prüfcode",
  "table.heading.doctor": "ARZT/ÄRZTIN",
  "table.heading.email": "E-MAIL ADRESSE",
  "table.heading.firstname": "VORNAME",
  "table.heading.interaction": "INTERAKTION",
  "table.heading.last_active_at": "ZULETZT AKTIV",
  "table.heading.lastname": "NACHNAME",
  "table.heading.pharmgenetix_id": "ID",
  "table.heading.plan": "UMFANG",
  "table.heading.report": "BEFUND",
  "table.heading.reports": "BEFUNDE",
  "table.heading.reports_in_process_and_total": "in Bearbeitung/gesamt",
  "table.heading.selection": "AUSWAHL",
  "table.heading.single_medication": "EINZELMEDIKATION",
  "table.heading.single_medication_short": "EINZELMED.",
  "table.heading.training": "Training Completed",
  "texterify_timestamp": "2022-12-19T13:55:05Z",
  "mfa.form_header": "Verifizierungscode eingeben",
  "mfa.instruction": "In Kürze erhalten Sie einen Verifizierungscode per E-Mail. Geben Sie diesen Code bitte im untenstehenden Feld ein.",
  "mfa.code_label": "Verifizierungscode",
  "mfa.submit": "Verifizierungscode senden",
  "mfa.code_error": "Feld darf nicht leer sein",
  "mfa.button_label_enabled": "Zwei-Faktor-Authentifizierung ist derzeit aktiviert. Klicken Sie, um zu deaktivieren.",
  "mfa.button_label_disabled": "Zwei-Faktor-Authentifizierung ist derzeit deaktiviert. Klicken Sie, um zu aktivieren.",
  "mfa.remember_device_label": "Auf diesem Gerät in den nächsten 90 Tagen nicht nach dem Bestätigungscode fragen.",
  "mfa.triesLeftError": "Falscher Verifizierungscode. Verbleibende Versuche: {tries}.",
  "mfa.authError":"Falsche Anmeldedaten. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.",
  "dialog.reportConfirmation.title": "Befundbesprechung hat stattgefunden",
  "dialog.reportConfirmation.undoTitle": "Befundbesprechung zurücksetzen",
  "dialog.reportConfirmation.body": "Ich bestätige hiermit, dass ich den Patienten <b>{name}, geb. {birthdate}</b> ausführlich über seinen pharmakogenetischen Befund aufgeklärt habe und ihn umfassend über die damit verbundenen Risiken informiert habe. Mir sind sämtliche Risiken der Anwendung bekannt.",
  "dialog.reportConfirmation.undoBody": "Möchten Sie die Bestätigung zurücksetzen, dass die Befundbesprechung für <b>{name}, geb. {birthdate}</b>, stattgefunden hat?",
};

export { german };
