import {
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Menu,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";
import React, { useState } from "react";
import { t } from "../../../../i18n/util";
import { API } from "../../../../network/API";
import { generalStore } from "../../../../stores/GeneralStore";
import { IPatient } from "../../../../types";
import { IInitialPatientValues } from "../../../forms/PatientForm";
import { Routes } from "../../../routers/Routes";
import { ConfirmEmailDialog } from "../../../ui/ConfirmResendDialog";
import { EmailStatus } from "../../../ui/EmailStatus";
import { SortableTableCell } from "../../../ui/SortableTableCell";
import { TableActionsContainer } from "../../../ui/TableActionsContainer";
import { Colors } from "../../../util/Colors";
import { copyTextToClipboard } from "../../../util/copyTextToClipboard";
import { fetchFile } from "../../../util/fetchFile";
import { Icon } from "../../../util/Icon";
import { getPatientLink } from "../../../util/Patient";
import { MfaToggle } from "./MfaToggle";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";

type IProps = {
    rows: IPatient[];
    onClickEditUser: (patient: IInitialPatientValues, uid: string) => void;
    onClickDeleteUser: (user: IInitialPatientValues, uid: string) => void;
    onClickResendEmail: (uid: string) => void;
    onClickReleaseReport: (patient: IPatient) => void;
    onChangeSort: (column: string) => void;
    orderBy?: string;
    orderDirection?: "asc" | "desc";
    enableMfaForUser: (userId: string) => () => void;
    disableMfaForUser: (userId: string) => () => void;
};

const getHeaderCells = () => [
    { column: "lastname", label: t("table.heading.lastname") },
    { column: "firstname", label: t("table.heading.firstname") },
    { column: "birthdate", label: t("table.heading.birthdate") },
    { column: "pharmgenetixId", label: t("table.heading.pharmgenetix_id") },
    { column: "pgxChecksum", label: t("table.heading.checksum") },
    { column: "lastLoginAt", label: t("table.heading.last_active_at") },
    { column: "doctorName", label: t("table.heading.doctor"), disableSort: true },
    { column: "username", label: t("table.heading.email") },
    { column: "canOptimize", label: t("table.heading.plan") },
    { column: "trainingCompleted", label: t("table.heading.training") },
    { label: null },
];

export const PatientsTable = ({
    rows,
    onClickEditUser,
    onClickResendEmail,
    onClickDeleteUser,
    onClickReleaseReport,
    onChangeSort,
    orderBy,
    orderDirection,
    enableMfaForUser,
    disableMfaForUser,
}: IProps) => {
    enum ECopyClickEvents {
        patient,
        resetPasswordLink,
    }

    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLLIElement) | null>(null);
    const [patient, setPatient] = useState<IPatient>();
    const [selectedUID, setSelectedUID] = useState<string>();
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState({ title: "", actionText: "" });

    const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmOpen = (uid: string, title: string, actionText: string) => {
        setSelectedUID(uid);
        setIsConfirmationModalOpen(true);
        setConfirmDialogProps({ title, actionText });
    };

    const handleConfirmClose = () => {
        setIsConfirmationModalOpen(false);
    };

    const handleMenuItemClick = (action: ECopyClickEvents) => {
        if (patient) {
            handleClose();
            // add timeout, otherwise the copyToClipboard function won't work
            setTimeout(() => {
                const copyText =
                    action === ECopyClickEvents.resetPasswordLink
                        ? patient.passwordResetLink
                        : getPatientLink(Routes.PGX_ADMIN.PATIENTS, patient.pharmgenetixId);
                copyTextToClipboard(copyText);
                generalStore.successMessage = t("success.copy");
            }, 300);
        }
    };

    return (
        <div style={{ overflow: "auto" }}>
            <ConfirmEmailDialog
                isOpen={isConfirmationModalOpen}
                onClose={handleConfirmClose}
                action={() => {
                    if (selectedUID) {
                        onClickResendEmail(selectedUID);
                        setIsConfirmationModalOpen(false);
                    }
                }}
                {...confirmDialogProps}
            />
            <Table data-id="patients_table" aria-label="table">
                <TableHead>
                    <TableRow>
                        {getHeaderCells().map((header, index) => (
                            <SortableTableCell
                                key={`${header.column}_${index}`}
                                column={header.column}
                                label={header.label}
                                orderBy={orderBy}
                                orderDirection={orderDirection}
                                onChangeSort={onChangeSort}
                                disable={header.disableSort}
                            />
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow data-id={`row_${index + 1}`} key={row.uid}>
                            <TableCell data-id="lastname">{row.lastname}</TableCell>
                            <TableCell data-id="firstname">{row.firstname}</TableCell>
                            <TableCell data-id="birthdate">{moment(row.birthdate).format("DD.MM.YYYY")}</TableCell>
                            <TableCell data-id="pharmgenetix_id">{row.pharmgenetixId}</TableCell>
                            <TableCell data-id="pharmgenetix_id">{row.pgxChecksum}</TableCell>
                            <TableCell data-id={"last_login_at"}>
                                {row.lastLoginAt ? moment(row.lastLoginAt).format("DD.MM.YYYY") : ""}
                            </TableCell>
                            <TableCell data-id="doctor">
                                {row.doctor.map(doctor => (
                                    <p key={doctor.uid}>
                                        {doctor.firstname} {doctor.lastname}
                                    </p>
                                ))}
                            </TableCell>
                            <TableCell data-id="username">{row.username}</TableCell>
                            <TableCell data-id="plan">
                                {row.canOptimize ? t("common.plan.totalCare") : t("common.plan.basic")}
                            </TableCell>
                            <TableCell data-id="training">
                                {row.trainingCompleted ? (
                                    <DoneIcon color="primary" titleAccess={t("common.yes")} />
                                ) : (
                                    <CloseIcon color="error" titleAccess={t("common.no")} />
                                )}
                            </TableCell>
                            <TableCell data-id={"actions"} align="right">
                                <TableActionsContainer>
                                    <MfaToggle
                                        mfaEnabled={row.mfaEnabled}
                                        enableMfa={enableMfaForUser(row.uid)}
                                        dissableMfa={disableMfaForUser(row.uid)}
                                    />
                                    {row.status === "emailSent" && row.username && (
                                        <div style={{ position: "relative" }}>
                                            <Icon
                                                data-id="resend_invitation_mail"
                                                name="email"
                                                title={t("table.action.send_invitation.title")}
                                                hoverColor={Colors.secondary}
                                                onClick={() =>
                                                    handleConfirmOpen(
                                                        row.uid,
                                                        t("confirm_resend_dialog.headline.text"),
                                                        t("confirm_resend_dialog.resend_password.label"),
                                                    )
                                                }
                                            />
                                            <EmailStatus status={row.emailStatus || "blue"} />
                                        </div>
                                    )}
                                    {row.status === "confirmed" && row.username && (
                                        <Icon
                                            data-id="reset_password"
                                            name="password"
                                            title={t("table.action.reset_password.title")}
                                            hoverColor={Colors.secondary}
                                            onClick={() =>
                                                handleConfirmOpen(
                                                    row.uid,
                                                    t("confirm_reset_dialog.headline.text"),
                                                    t("confirm_reset_dialog.label"),
                                                )
                                            }
                                        />
                                    )}
                                    {row.status !== "emailSent" && row.status !== "confirmed" && row.username && (
                                        <Icon
                                            data-id="resend_invitation_mail"
                                            name="email"
                                            title={t("table.action.locked_report.title")}
                                            disabled
                                        />
                                    )}
                                    <Icon
                                        data-id="edit"
                                        name="edit"
                                        title={t("table.action.edit.title")}
                                        hoverColor={Colors.secondary}
                                        onClick={() =>
                                            onClickEditUser(
                                                {
                                                    doctorUid: row.doctorUid,
                                                    firstname: row.firstname,
                                                    lastname: row.lastname,
                                                    language: row.language,
                                                    pharmgenetixId: row.pharmgenetixId,
                                                    birthdate: row.birthdate,
                                                    username: row.username,
                                                    originalPgxName: row.originalPgxName,
                                                    originalPdfName: row.originalPdfName,
                                                },
                                                row.uid,
                                            )
                                        }
                                    />
                                    <Icon
                                        data-id="download_report"
                                        name="pdf"
                                        title={t("table.action.download_report.title")}
                                        hoverColor={Colors.secondary}
                                        onClick={async () => {
                                            try {
                                                const report = await API.getPdfReport(row.uid);

                                                fetchFile(report);
                                            } catch (error) {
                                                console.error(error);
                                            }
                                        }}
                                    />
                                    <Tooltip
                                        title={
                                            row.reportReleased
                                                ? t("table.action.releasedReport")
                                                : t("table.action.unreleasedReport")
                                        }
                                    >
                                        {row.reportReleased ? (
                                            <IconButton
                                                aria-label={t("table.action.releasedReport")}
                                                onClick={() => {
                                                    onClickReleaseReport(row);
                                                }}
                                            >
                                                <QuestionAnswerOutlinedIcon style={{ color: Colors.released }} />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={() => {
                                                    onClickReleaseReport(row);
                                                }}
                                                aria-label={t("table.action.unreleasedReport")}
                                            >
                                                <AnnouncementOutlinedIcon color="error" />
                                            </IconButton>
                                        )}
                                    </Tooltip>
                                    <Icon
                                        data-id="copy"
                                        name="copy"
                                        title={t("table.action.copy.patient")}
                                        hoverColor={Colors.secondary}
                                        onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                                            setPatient(row);
                                            handleClick(e);
                                        }}
                                    />
                                    <Icon
                                        data-id="delete"
                                        name="trash"
                                        color={Colors.danger}
                                        title={t("table.action.delete.patient")}
                                        hoverColor={Colors.secondary}
                                        onClick={() => {
                                            onClickDeleteUser(row, row.uid);
                                        }}
                                    />
                                </TableActionsContainer>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(ECopyClickEvents.patient)}>
                    {t("table.action.copy.patient")}
                </MenuItem>
                {patient?.passwordResetLink ? (
                    <MenuItem onClick={() => handleMenuItemClick(ECopyClickEvents.resetPasswordLink)}>
                        {t("table.action.copy.passwordResetLink")}
                    </MenuItem>
                ) : null}
            </Menu>
        </div>
    );
};
