const italian = {
  "add_doctor_dialog.confirmation_text": "Confermo che il mio medico accetta di essere contattato da PharmGenetix.",
  "add_doctor_dialog.description": "NOTA BENE: Poiché il software PGx-Optimizer® è un dispositivo medico, è obbligatoria la formazione del medico curante sul corretto utilizzo. La formazione richiede circa 30 minuti. Si prega di discutere in anticipo con il proprio medico curante se desidera partecipare alla formazione. È possibile rimuovere il medico curante indicato in qualsiasi momento.",
  "add_doctor_dialog.download_informations_pdf": "Il medico non è ancora stato informato",
  "add_doctor_dialog.form.city_error": "Inserire una comune, cittá",
  "add_doctor_dialog.form.firstname_error": "Inserire un nome",
  "add_doctor_dialog.form.lastname_error": "Inserire un cognome",
  "add_doctor_dialog.form.mail_error": "Inserire un indirizzo e-mail valido",
  "add_doctor_dialog.form.next.text": "Il medico è interessato alla formazione PGx",
  "add_doctor_dialog.form.phone_error": "Inserire un numero di telefono valido",
  "add_doctor_dialog.form.submit.text": "Invia richiesta",
  "add_doctor_dialog.form_group.contact_data.text": "Dati di contatto del medico",
  "add_doctor_dialog.form_group.data.text": "Dati del medico",
  "add_doctor_dialog.headline": "Inserisci medico",
  "add_doctor_dialog.label.city": "Comune, Cittá",
  "add_doctor_dialog.label.contact_type": "Tipo di contatto",
  "add_doctor_dialog.label.email": "E-mail",
  "add_doctor_dialog.label.firstname": "Nome",
  "add_doctor_dialog.label.lastname": "Cognome",
  "add_doctor_dialog.label.phone": "Telefono",
  "add_doctor_dialog.label.zip": "CAP",
  "summary_dialog.description": "TODO",
  "summary_dialog.form.firstname_error": "Inserire un nome",
  "summary_dialog.form.lastname_error": "Inserire un cognome",
  "summary_dialog.form.submit.text": "TODO",
  "summary_dialog.headline": "TODO",
  "summary_dialog.label.field": "TODO",
  "summary_dialog.label.firstname": "Nome",
  "summary_dialog.label.lastname": "Cognome",
  "summary_dialog.generic_error": "Fehler beim Erstellen der Summary Report.",
  "summary_dialog.medication_mismatch_error": "Die angezeigten Daten sind veraltet. Bitte aktualisieren Sie die Seite, um die neuesten Informationen zu erhalten.",
  "common.enzyme.interaction_with": "Interazione con",
  "common.enzyme.weight": "Influenza sul risultato generale",
  "common.enzyme.weight.high": "elevato",
  "common.enzyme.weight.low": "Minimo",
  "common.enzyme.weight.medium": "Intermedio",
  "common.error": "Errore",
  "common.here": "qui",
  "common.plan.basic": "Basic",
  "common.plan.totalCare": "Total Care",
  "common.print": "Stampa",
  "common.print.close": "Chiudi anteprima di stampa",
  "common.yes": "Sì",
  "common.no": "No",
  "common.unexpectedError": "Ops! Qualcosa è andato storto. Si prega di riprovare più tardi.",
  "confirm_delete_dialog.delete": "Elimina",
  "confirm_delete_dialog.headline.text": "Vuole davvero eliminare queste notizie?",
  "confirm_resend_dialog.cancel.label": "Annulla",
  "confirm_resend_dialog.headline.text": "Vuole davvero inviare l'invito?",
  "confirm_resend_dialog.resend_password.label": "Invia l'invito",
  "confirm_reset_dialog.headline.text": "Vuole davvero reimpostare la password?",
  "confirm_reset_dialog.label": "Reimposta la password",
  "date.cancelLabel": "Annulla",
  "date.clearLabel": "Elimina",
  "date.validation_error": "Inserire una data valida",
  "dialog.button_cancel": "Annulla",
  "dialog.button_confirm": "Conferma",
  "dialog.button_lock": "Blocca",
  "dialog.button_next": "Continua",
  "dialog.button_release": "Autorizza",
  "dialog.button_save": "Salva",
  "doctor.appUsageWarningDialog.button.text": "Letto e compreso",
  "doctor.appUsageWarningDialog.text": `
        Facciamo presente che il risultato farmacogenetico, l'analisi delle interazioni e la raccomandazione della dose sono da considerarsi come informazioni destinate esclusivamente ai professionisti sanitari.<br><br>
        Esse non possono sostituire l’esperienza e la conoscenza del medico curante nell’emettere la diagnosi o nello stabilire il piano terapeutico e non lo sollevano dall’obbligo di cautela e dalla responsabilità medica nella prescrizione.<br><br>
        Qualsiasi prescrizione farmacologica richiede una scelta accurata e coscienziosa basata sull'anamnesi e sul quadro clinico del paziente, nonché un'adeguata osservazione del paziente dopo la somministrazione del medicinale.<br><br>
        L'interazione farmacologica qui calcolata si riferisce solo alla genetica del metabolismo dei farmaci. Si noti che possono esistere altre interazioni che non sono state considerate in questa sede.<br><br>
        Occorre inoltre tenere in considerazione che il fenotipo può essere alterato da rare varianti del DNA ancora sconosciute o non testate a livello funzionale.`,
  "doctor.appUsageWarningDialog.title": "Avvertenza",
  "doctor.notReleasedReportInfo": "Una discussione dei risultati è ancora aperta.",
  "doctor.notReleasedReportsInfo": "{patientsWithoutReleasedReport} discussioni dei risultati sono ancora aperte.",
  "patient.replaceWarningDialog.approval.text": "Letto e compreso",
  "patient.replaceWarningDialog.text": "Si noti che la decisione finale sulla terapia può essere presa solo da un medico. I risultati del profilo farmacogenetico non descrivono la situazione complessiva del paziente e possono essere applicati solo considerando il quadro completo (ad es. funzione renale, età, peso, allergie, ecc.).\nEvitare di modificare arbitrariamente e in modo incontrollato la dose del farmaco o di sospenderlo. Consultare un medico prima di apportare qualsiasi modifica.",
  "patient.replaceWarningDialog.title": "Nota",
  "dropdown.label.atcGroups": "Gruppi ATC",
  "dropzone.button.label_empty": "SFOGLIA FILE",
  "dropzone.button.label_selected": "Sostituisci file",
  "error.addDoctor": "Non è stato possibile creare il profilo del medico",
  "error.addDoctor.emailAlreadyInUse": "L'ID del medico esiste già",
  "error.addPatient": "Non è stato possibile creare il profilo del paziente",
  "error.calculate": "Non è stato possibile caricare il profilo delle interazioni",
  "error.copy": "Errore durante la copia negli appunti",
  "error.csv_export": "Errore durante la creazione del file CSV",
  "error.downloadReport": "Errore durante il download del referto",
  "error.editDoctor": "Non è stato possibile modificare il profilo del medico",
  "error.editPatient": "Non è stato possibile modificare il profilo del paziente",
  "error.emailAlreadyInUse": "L'ID del medico esiste già",
  "error.errorScreenMessage": "Riprovare più tardi. Se l'errore persiste, cliccare su {errorLink} per contattarci.",
  "error.getStatus": "Errore durante il caricamento dello stato",
  "error.loadDoctorsList": "Errore durante il caricamento della lista di medici",
  "error.loadMedication": "Errore durante il caricamento della terapia",
  "error.loadPatient": "Errore durante il caricamento dei dati del paziente",
  "error.loadPatientsList": "Errore durante il caricamento della lista di pazienti",
  "error.loadSingleMedGroups": "Errore durante il caricamento dei gruppi di medicinali",
  "error.loadSubstanceData": "Errore durante il caricamento dei dati necessari sulla sostanza",
  "error.loadSubstances": "Errore durante il caricamento della lista di principi attivi",
  "error.optimize": "Errore durante l'ottimizzazione della terapia",
  "error.optimizer_timeout": "Il calcolo era troppo complesso ed è stato interrotto. Eventualmente ridurre il numero di sostanze selezionate o bloccarne alcune.",
  "error.releaseReport": "Errore durante l'autorizzazione",
  "error.resendEmail": "Non è stato possibile inviare l'e-mail",
  "error.resetPassword": "Non è stato possibile reimpostare la password",
  "error.saveMedication": "Errore durante il salvataggio della terapia",
  "error.serviceUnavailable": "Il servizio non è disponibile al momento. Si prega di riprovare più tardi.",
  "gender_disclaimer": "Per consentire una migliore leggibilità, si è scelto di adottare il maschile sovraesteso neutro. Tutti i riferimenti alle persone includono tutti i generi.",
  "hisoty.open_history_button.text": "Apri versione",
  "history.description": "Tutte le versioni dell'analisi dei medicinali vengono salvate qui con la relativa data. Selezionare le versioni precedenti per visualizzarle nel dettaglio o continuare la modifica.",
  "history.info.headline": "Validità dell'analisi dei medicinali",
  "history.info.text": "La banca dati viene aggiornata continuamente in base alle nuove scoperte e conoscenze scientifiche. In seguito al confronto automatico con la banca dati aggiornata, le analisi meno recenti possono quindi generare risultati diversi.",
  "history.open_history_button.text": "Apri versione",
  "history.title": "Cronologia delle analisi",
  "language.de": "Tedesco",
  "language.en": "Inglese",
  "language.english": "Inglese",
  "language.german": "Tedesco",
  "language.it": "Italiano",
  "language.italian": "Italiano",
  "language.language": "Lingua",
  "loadingscreen.heading": "Calcolo in corso ...",
  "loadingscreen.info_text": "Verifica delle combinazioni possibili",
  "navigation.help.contact": "Contatti",
  "navigation.help.label": "SUPPORTO",
  "navigation.help.manual": "Guida",
  "navigation.help.privacy_policy": "Informativa sulla protezione dei dati",
  "navigation.help.site_notice": "Note legali",
  "navigation.logout.label": "Termina sessione",
  "news.content": "Contenuto",
  "news.date": "Data",
  "news.title": "Titolo",
  "news.patientEnabled": "Per i pazienti",
  "news.doctorEnabled": "Per i medici",
  "news.published": "Pubblicato",
  "news.unpublished": "Non pubblicato",
  "news.unreadNewsDialog.button.text": "Letto",
  "news.unreadNewsDialog.title": "Notizie",
  "news_modal.create_news": "Crea nuovo contributo",
  "news_modal.edit_news": "Modifica contributo",
  "news_modal.form.error.content": "Inserire un contenuto",
  "news_modal.form.error.title": "Inserire un titolo",
  "news_modal.form.label.content": "Contenuto",
  "news_modal.form.label.title": "Titolo",
  "news_modal.form.label.patientEnabled": "Pubblicare per i pazienti",
  "news_modal.form.label.doctorEnabled": "Pubblicare per i medici",
  "news_modal.publish": "Pubblica",
  "optimizer.legend.danger.text": "Considera alternativa",
  "optimizer.legend.decrease.high.text": "Elevato",
  "optimizer.legend.decrease.low.text": "Minimo",
  "optimizer.legend.decrease.medium.text": "Intermedio",
  "optimizer.legend.enzymeActivity.danger.text": "Variante critica o interazione critica",
  "optimizer.legend.enzymeActivity.decrease.high.text": "Elevato",
  "optimizer.legend.enzymeActivity.decrease.low.text": "Minimo",
  "optimizer.legend.enzymeActivity.decrease.medium.text": "Intermedio",
  "optimizer.legend.enzymeActivity.heading": "Attività enzimatica",
  "optimizer.legend.enzymeActivity.increase.high.text": "Elevato",
  "optimizer.legend.enzymeActivity.increase.low.text": "Minimo",
  "optimizer.legend.enzymeActivity.increase.medium.text": "Intermedio",
  "optimizer.legend.enzymeActivity.okay.text": "Normale funzione enzimatica",
  "optimizer.legend.enzymeActivity.poorMetabolizer.text": "Metabolizzatori poveri",
  "optimizer.legend.enzymeActivity.section.decrease.text": "Riduzione dell'attività enzimatica:",
  "optimizer.legend.enzymeActivity.section.increase.text": "Aumento dell'attività enzimatica:",
  "optimizer.legend.enzymeActivity.section.okay.text": "Normale:",
  "optimizer.legend.enzymeActivity.section.other.text": "Altro:",
  "optimizer.legend.enzymeActivity.section.warnings.text": "Avvertimento:",
  "optimizer.legend.enzymeActivity.ultraRapidMetabolizer.text": "Metabolizzatori ultrarapidi",
  "optimizer.legend.enzymeActivity.unknown.text": "Non testato",
  "optimizer.legend.heading": "Raccomandazioni",
  "optimizer.legend.heading.info": "Raccomandazioni basate sullo stato attuale delle conoscenze e sui risultati ottenuti dall'analisi.",
  "optimizer.legend.increase.high.text": "Elevato",
  "optimizer.legend.increase.low.text": "Minimo",
  "optimizer.legend.increase.medium.text": "Intermedio",
  "optimizer.legend.info": "I principi attivi che non compaiono nella banca dati possiedono una rilevanza farmacogenetica ancora sconosciuta, non sufficientemente verificata o non data. La banca dati dei principi attivi viene regolarmente aggiornata sulla base delle raccomandazioni delle agenzie regolatorie, dei consorzi scientifici internazionali di farmacogenetica e della letteratura medica (peer-reviewed).",
  "optimizer.legend.information.cpic": "CPIC Guidelines",
  "optimizer.legend.information.text": "Ulteriori informazioni:",
  "optimizer.legend.notTested.badge.text": "Non metabolizzato da CYP",
  "optimizer.legend.okay.text": "Dosaggio alle raccomandazioni del foglietto illustrativo",
  "optimizer.legend.section.decrease.text": "Prendere in considerazione una riduzione del dosaggio:",
  "optimizer.legend.section.increase.text": "Prendere in considerazione un aumento del dosaggio:",
  "optimizer.legend.section.not_recommended.badge.text": "Farmaco non raccomandato",
  "optimizer.legend.section.okay.text": "Normale:",
  "optimizer.legend.section.other.text": "Altro:",
  "optimizer.legend.section.warning.badge.text": "Aumentato rischio di effetti collaterali dovuto a mutazioni di un trasportatore o di una proteina collegata",
  "optimizer.legend.section.warnings.text": "Avvertimento:",
  "optimizer.legend.unknown.badge.text": "Non testato",
  "password_strength_checker.info_text_begin": "Inserire",
  "password_strength_checker.info_text_end": "per proteggere ulteriormente la password",
  "password_strength_checker.lower_case_letter": "Lettere minuscole",
  "password_strength_checker.medium_password": "Livello medio di sicurezza della password",
  "password_strength_checker.password_too_short": "La password deve contenere almeno 8 caratteri.",
  "password_strength_checker.special_character": "Caratteri speciali",
  "password_strength_checker.strong_password": "Livello alto di sicurezza della password",
  "password_strength_checker.upper_case_letter": "Lettere maiuscole",
  "password_strength_checker.weak_password": "Livello basso di sicurezza della password",
  "screen.doctor.auto_optimize.heading": "Combinazioni sostitutive calcolate automaticamente",
  "screen.doctor.auto_optimize.navigation.replace_automatically": "Sostituisci automaticamente",
  "screen.doctor.auto_optimize.no_enzymes_available": "Nessun enzima disponibile",
  "screen.doctor.auto_optimize.section.combination": "Combinazione",
  "screen.doctor.auto_optimize.section.combination.current": "STATO ATTUALE",
  "screen.doctor.auto_optimize.section.combination.replacement": "COMBINAZIONE SOSTITUTIVA",
  "screen.doctor.auto_optimize.section.combination.select": "SELEZIONA",
  "screen.doctor.auto_optimize.section.combinations": "Combinazioni",
  "screen.doctor.auto_optimize.section.replacement_in_different_level": "Combinazioni sostitutive possibili",
  "screen.doctor.auto_optimize.section.replacement_in_different_level.description": "Sostituzione ATC possibile",
  "screen.doctor.auto_optimize.section.replacement_in_same_level": "Combinazione sostitutiva nello stesso livello ATC",
  "screen.doctor.auto_optimize.section.replacement_in_same_level.description": "nello stesso livello ATC",
  "screen.doctor.auto_optimize.show_less_information": "NASCONDI ENZIMI",
  "screen.doctor.auto_optimize.show_more_information": "MOSTRA ENZIMI",
  "screen.doctor.auto_optimize.text": "Selezionare una combinazione tra le proposte di ottimizzazione disponibili.",
  "screen.doctor.manual_optimize.enzyme.cyp3a5.info": "A livello globale, la mutazione *3 è dominante con l'88,7% e quindi l'attività enzimatica fortemente ridotta di CYP3A5 rappresenta l'attività enzimatica \"normale\".",
  "screen.doctor.manual_optimize.interaction_preview.explanation": "Anteprima del nuovo profilo di interazione di tutti i principi attivi con il farmaco sostitutivo per {substance}.",
  "screen.doctor.manual_optimize.interaction_preview.heading": "Anteprima dell'interazione",
  "screen.doctor.manual_optimize.interaction_preview.hide_legend": "NASCONDI LA LEGENDA",
  "screen.doctor.manual_optimize.interaction_preview.show_legend": "MOSTRA LA LEGENDA",
  "screen.doctor.manual_optimize.interaction_preview.show_less_information": "NASCONDI ENZIMI",
  "screen.doctor.manual_optimize.interaction_preview.show_more_information": "MOSTRA ENZIMI",
  "screen.doctor.manual_optimize.navigation.replace_manually": "Sostituire manualmente il principio attivo",
  "screen.doctor.manual_optimize.prodrug.title": "Profarmaco",
  "screen.doctor.manual_optimize.prodrug.text": "È già incluso nel calcolo.",
  "screen.doctor.manual_optimize.substance_picker.dropdown.option.substance_count.text": "Principi attivi",
  "screen.doctor.manual_optimize.substance_picker.heading": "Principi attivi sostitutivi",
  "screen.doctor.manual_optimize.substance_picker.keep_substance.button.text": "Mantieni principio attivo",
  "screen.doctor.manual_optimize.substance_picker.save_substance.button.text": "Adotta principio attivo sostitutivo",
  "screen.doctor.manual_optimize.substance_picker.select_substance.text": "Seleziona un principio attivo:",
  "screen.doctor.navigation.news": "Novità",
  "screen.doctor.navigation.patient_overview": "Panoramica paziente",
  "screen.doctor.news.description": "Qui si trovano tutte le informazioni su Pgx-Optimizer.",
  "screen.doctor.optimize.actions.analysisHistory": "Cronologia delle analisi",
  "screen.doctor.optimize.actions.reset": "RIPRISTINA",
  "screen.doctor.optimize.actions.searchbar.placeholder": "Cerca e inserisci principio attivo o nome commerciale",
  "screen.doctor.optimize.heading": "Controllo medicinali",
  "screen.doctor.optimize.history_information.headline": "Versione del {date}, creata da {doctorName}",
  "screen.doctor.optimize.no_medication.heading": "Informi il paziente sulla tollerabilità dei medicinali selezionati.",
  "screen.doctor.optimize.no_medication.to_medication_groups": "Vai ai gruppi di medicinali",
  "screen.doctor.optimize.optimize_medicaction": "Ottimizza i medicinali",
  "screen.doctor.optimize.replace_automatically.replacement.heading": "Principi attivi da somministrare",
  "screen.doctor.optimize.replace_automatically.replacement.save": "Salva",
  "screen.doctor.optimize.replace_automatically.replacement.sub_heading": "Qui viene visualizzato il risultato del nuovo calcolo. È possibile salvarlo e visualizzarlo successivamente alla voce “Cronologia delle analisi”.",
  "screen.doctor.optimize.sub_text": "Inserire qui i principi attivi o i medicinali che si desidera analizzare.",
  "screen.doctor.optimize.getSummary": "Print",
  "screen.doctor.optimize.timeout.warning": "Attenzione: questo calcolo potrebbe richiedere troppo tempo. Ridurre il numero dei principi attivi selezionati.",
  "screen.doctor.patients.empty_list.heading": "Non sono ancora disponibili dati relativi al paziente.",
  "screen.doctor.patients.empty_list.pharmgenetix_info": "PharmGenetix – Chi siamo",
  "screen.doctor.patients.empty_list.text": "Con l'invio del primo campione di sangue, compariranno qui il risultato dell'analisi e una panoramica di tutti i campioni analizzati.",
  "screen.doctor.patients.searchbar.placeholder": "Cerca paziente",
  "screen.doctor.replacement_notice.dialog.placeholder": "Motivazione della combinazione sostitutiva",
  "screen.doctor.replacement_notice.dialog.submit_with_notice": "Allega nota",
  "screen.doctor.replacement_notice.dialog.submit_without_notice": "Continua senza la nota",
  "screen.doctor.replacement_notice.dialog.text": "Ci sarebbe una combinazione sostitutiva migliore. Selezionare comunque la combinazione e inserire una motivazione?",
  "screen.doctor.replacement_notice.dialog.title": "Inserisci nota",
  "screen.doctor.select_group.dialog.dialog.form.multiple.group.validation_error": "Selezionare almeno un gruppo di principi attivi",
  "screen.doctor.select_group.dialog.dialog.form.single.group.validation_error": "Selezionare un gruppo di principi attivi",
  "screen.doctor.select_group.dialog.multiple_groups.text": "Selezionare uno o più gruppi nei quali il principio attivo deve essere sostituito.",
  "screen.doctor.select_group.dialog.single_group.text": "Selezionare il gruppo nel quale il principio attivo deve essere sostituito.",
  "screen.forgot_password.description": "Riceverà un link via e-mail per reimpostare la password.",
  "screen.forgot_password.form.email.label": "E-Mail",
  "screen.forgot_password.form.email.validation_error": "Inserire un indirizzo e-mail valido",
  "screen.forgot_password.form.error": "Non è stato possibile inviare l'e-mail",
  "screen.forgot_password.form.submit": "Invia link",
  "screen.forgot_password.form.success": "Invio e-mail riuscito",
  "screen.forgot_password.title": "Ho dimenticato la password",
  "screen.login.error_during_login": "C'é stato un errore durante il login",
  "screen.login.form.email.label": "E-Mail",
  "screen.login.form.email.validation_error": "Inserire un indirizzo e-mail valido",
  "screen.login.form.forgot_password": "PASSWORD DIMENTICATA?",
  "screen.login.form.password.label": "Password",
  "screen.login.form.password.validation_error": "Inserire una password",
  "screen.login.form.submit": "Accedi",
  "screen.login.form.validation_error_email_and_password": "Inserire un indirizzo e-mail valido e la password",
  "screen.login.invalid_password_or_email": "Autenticazione fallita. Verificare l'e-mail e la password.",
  "screen.not_found.title": "Non è stato possibile trovare la pagina richiesta.",
  "screen.patient.medication_check.heading": "Controllo medicinali",
  "screen.patient.medication_check.text": "Panoramica sui principi attivi testati come monoterapia.",
  "screen.patient.medication_groups.heading": "Medicinali e gruppi di principi attivi",
  "screen.patient.medication_groups.print.name.label": "NOME PAZIENTE",
  "screen.patient.medication_groups.text": "Principi attivi elencati come monoterapia, senza analisi dell'interazione.",
  "screen.pgx_admin.add_edit_doctor.dialog.contact_details.text": "Dati di contatto",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.helper_text": "Con questo indirizzo e-mail l'utente otterrà automaticamente l'accesso a pharmgenetix.com.",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.label": "E-Mail",
  "screen.pgx_admin.add_edit_doctor.dialog.form.email.validation_error": "Inserire un indirizzo e-mail valido",
  "screen.pgx_admin.add_edit_doctor.dialog.form.firstname.label": "Nome",
  "screen.pgx_admin.add_edit_doctor.dialog.form.firstname.validation_error": "Inserire un nome",
  "screen.pgx_admin.add_edit_doctor.dialog.form.language": "Lingua predefinita",
  "screen.pgx_admin.add_edit_doctor.dialog.form.lastname.label": "Cognome",
  "screen.pgx_admin.add_edit_doctor.dialog.form.lastname.validation_error": "Inserire un cognome",
  "screen.pgx_admin.add_edit_doctor.dialog.form.pharmgenetix_id.label": "ID medico PharmGenetix (opzionale)",
  "screen.pgx_admin.add_edit_doctor.dialog.personal_details.text": "Dati personali",
  "screen.pgx_admin.add_edit_doctor.dialog.title": "Nuovo medico",
  "screen.pgx_admin.add_edit_patient.dialog.contact_details.text": "Dati di contatto",
  "screen.pgx_admin.add_edit_patient.dialog.form.birthdate.label": "Data di nascita (GG.MM.AAAA)",
  "screen.pgx_admin.add_edit_patient.dialog.form.birthdate.validation_empty_error": "Inserire una data di nascita",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.helper_text": "Se il nome del medico non compare nell'elenco, per prima cosa impostare il profilo del medico alla voce “Gestione medici”.",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.label": "Nome del medico",
  "screen.pgx_admin.add_edit_patient.dialog.form.doctor.validation_error": "Selezionare un medico",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.helper_text": "Non appena il referto sarà disponibile, il paziente otterrà l'accesso a pharmgenetix.com a questo indirizzo.",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.label": "E-Mail",
  "screen.pgx_admin.add_edit_patient.dialog.form.email.validation_error": "Inserire un indirizzo e-mail valido",
  "screen.pgx_admin.add_edit_patient.dialog.form.firstname.label": "Nome",
  "screen.pgx_admin.add_edit_patient.dialog.form.firstname.validation_error": "Inserire un nome",
  "screen.pgx_admin.add_edit_patient.dialog.form.language": "Lingua predefinita",
  "screen.pgx_admin.add_edit_patient.dialog.form.lastname.label": "Cognome",
  "screen.pgx_admin.add_edit_patient.dialog.form.lastname.validation_error": "Inserire un cognome",
  "screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.label": "File PGx",
  "screen.pgx_admin.add_edit_patient.dialog.form.pgx_file.validation_empty_error": "Selezionare un file PGx (.pgx-report)",
  "screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.label": "ID paziente PharmGenetix",
  "screen.pgx_admin.add_edit_patient.dialog.form.pharmgenetix_id.validation_error": "Inserire l'ID del paziente",
  "screen.pgx_admin.add_edit_patient.dialog.form.report_file.label": "Referto in PDF",
  "screen.pgx_admin.add_edit_patient.dialog.form.report_file.validation_empty_error": "Selezionare un file PDF",
  "screen.pgx_admin.add_edit_patient.dialog.personal_details.text": "Dati personali",
  "screen.pgx_admin.add_edit_patient.dialog.responsible_doctor.text": "Medico responsabile",
  "screen.pgx_admin.add_edit_patient.dialog.title": "Nuovo paziente",
  "screen.pgx_admin.confirm_dialog.description.doctor": "Eliminare questo medico?",
  "screen.pgx_admin.confirm_dialog.description.link_to_patient_text": "vai al paziente",
  "screen.pgx_admin.confirm_dialog.description.patient": "Eliminare questo paziente?",
  "screen.pgx_admin.confirm_dialog.description.reassign_patients": "Per eliminare questo medico, assegnare prima un nuovo medico al paziente.",
  "screen.pgx_admin.confirm_dialog.headline.doctor": "Elimina medico",
  "screen.pgx_admin.confirm_dialog.headline.patient": "Elimina paziente",
  "screen.pgx_admin.csv_export.button.label": "CSV EXPORT",
  "screen.pgx_admin.doctors.actions.add.button.label": "Nuovo medico",
  "screen.pgx_admin.doctors.actions.searchbar.placeholder": "Cerca medico",
  "screen.pgx_admin.doctors.empty_list.add.button.label": "Inserisci il primo medico",
  "screen.pgx_admin.doctors.empty_list.text": "Non è ancora stato inserito un medico",
  "screen.pgx_admin.navigation.tab.doctor_management": "Gestione medici",
  "screen.pgx_admin.navigation.tab.news": "Novità",
  "screen.pgx_admin.navigation.tab.patient_management": "Gestione pazienti",
  "screen.pgx_admin.news.add_news": "Crea nuovo contributo",
  "screen.pgx_admin.patients.actions.add.button.label": "Nuovo paziente",
  "screen.pgx_admin.patients.actions.searchbar.placeholder": "Cerca paziente",
  "screen.pgx_admin.patients.empty_list.add.button.label": "Inserisci il primo paziente",
  "screen.pgx_admin.patients.empty_list.text": "Non è ancora stato inserito un paziente",
  "screen.set_password.description": "Per una password sicura usare una combinazione di caratteri speciali, lettere maiuscole e minuscole e numeri. La password deve contenere almeno 8 caratteri.",
  "screen.set_password.form.error_expired_token": "Il link fornito è scaduto. Richiedere un nuovo link.",
  "screen.set_password.form.error_invalid_token": "Il link fornito non è valido. Richiedere un nuovo link.",
  "screen.set_password.form.error_weak_password": "La password inserita non soddisfa i requisiti minimi. Scegliere un'altra password.",
  "screen.set_password.form.password.confirm.label": "Ripetere la nuova password",
  "screen.set_password.form.password.confirm.required.validation_error": "Ripetere la password",
  "screen.set_password.form.password.confirm.validation_error": "Le password non coincido",
  "screen.set_password.form.password.label": "Nuova password",
  "screen.set_password.form.password.validation_error": "Inserire una password valida",
  "screen.set_password.form.submit": "Stabilire una nuova password",
  "screen.set_password.form.success": "Creazione password riuscita. È in corso il trasferimento automatico alla pagina di autenticazione.",
  "screen.set_password.invalid_token.description": "Il link fornito non è più valido. È possibile effettuare l'accesso con la propria password impostata.",
  "screen.set_password.invalid_token.title": "Link non valido",
  "screen.set_password.title": "Password impostata",
  "select.no_options": "Nessuna opzione",
  "success.copy": "Copiato negli appunti",
  "success.resendEmail": "Invio e-mail riuscito",
  "table.action.analyse": "ANALISI",
  "table.action.copy.passwordResetLink": "Copia link per reimpostare la password",
  "table.action.copy.patient": "Copia link paziente",
  "table.action.copy.title": "Copia link",
  "table.action.delete.doctor": "Elimina medico",
  "table.action.delete.patient": "Elimina paziente",
  "table.action.delete.title": "Rimuovi dalla lista",
  "table.action.delete_doctor.text": "Elimina medico",
  "table.action.delete_patient.description.text": "Vuole davvero eliminare il paziente?",
  "table.action.delete_patient.headline.text": "Eliminare il paziente?",
  "table.action.delete_patient.text": "Elimina paziente",
  "table.action.download_report.title": "Scarica referto",
  "table.action.dropdown.download_report": "Scarica referto\n",
  "table.action.dropdown.medication_check": "Controllo medicinali\n",
  "table.action.dropdown.medication_group": "Gruppo di medicinali\n",
  "table.action.edit.title": "Modifica\n",
  "table.action.fix": "BLOCCA",
  "table.action.fixed": "BLOCCATO",
  "table.action.locked_report.title": "Referto non ancora autorizzato",
  "table.action.medication_check.title": "Controllo medicinali",
  "table.action.medication_groups.title": "Gruppi di medicinali",
  "table.action.optimize": "OTTIMIZZA",
  "table.action.unreleasedReport": "In attesa di revisione dei risultati",
  "table.action.releasedReport": "La discussione dei risultati ha avuto luogo",
  "table.action.reset_password.title": "Reimposta la password",
  "table.action.send_invitation.title": "Invia l'invito",
  "table.heading.active_agent": "PRINCIPIO ATTIVO",
  "table.heading.birthdate": "DATA DI NASCITA",
  "table.heading.checksum": "Codice di verifica",
  "table.heading.doctor": "MEDICO",
  "table.heading.email": "E-MAIL",
  "table.heading.firstname": "NOME",
  "table.heading.interaction": "INTERAZIONE",
  "table.heading.last_active_at": "ULTIMA ATTIVITÀ",
  "table.heading.lastname": "COGNOME",
  "table.heading.pharmgenetix_id": "ID",
  "table.heading.plan": "PACCHETTO",
  "table.heading.report": "REFERTO",
  "table.heading.reports": "REFERTI",
  "table.heading.reports_in_process_and_total": "in elaborazione/totale",
  "table.heading.selection": "SELEZIONE",
  "table.heading.single_medication": "MONOTERAPIA",
  "table.heading.single_medication_short": "MONOTER.",
  "table.heading.training": "Formazione completata",
  "texterify_timestamp": "2022-12-19T13:55:07Z",
  "mfa.form_header": "Inserisci codice",
  "mfa.instruction": "Riceverai a breve un codice di verifica via email. Inserisci questo codice nel campo sottostante.",
  "mfa.code_label": "Codice di verifica",
  "mfa.submit": "Inviare codice di verifica",
  "mfa.code_error": "Campo obbligatorio",
  "mfa.button_label_enabled": "La autenticazione a due fattori è attualmente abilitata. Cliccare per disabilitare.",
  "mfa.button_label_disabled": "La autenticazione a due fattori è attualmente disattivata. Cliccare per abilitare.",
  "mfa.remember_device_label": "Non chiedere il codice di verifica su questo dispositivo per i prossimi 90 giorni.",
  "mfa.triesLeftError": "Codice di verifica errato. Tentativi rimanenti: {tries}.",
  "mfa.authError": "Dati di accesso errati. Si prega di controllare i dati inseriti e riprovare.",
  "dialog.reportConfirmation.title": "Si è discusso dei risultati",
  "dialog.reportConfirmation.undoTitle": "Annullamento della riunione di constatazione",
  "dialog.reportConfirmation.body": "Con la presente confermo di aver informato dettagliatamente il paziente, <b>{name} e {birthdate}</b> di nascita, sui suoi risultati farmacogenetici e di avergli fornito informazioni esaurienti sui rischi associati. Sono a conoscenza di tutti i rischi dell'applicazione.",
  "dialog.reportConfirmation.undoBody": "Desiderate annullare la conferma che la discussione del referto per <b>{name}, nato/a il {birthdate}</b>, abbia avuto luogo?",
};

export { italian };
